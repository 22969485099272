import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DrpatilService } from 'src/app/shared/providers/drpatil.service';

@Component({
  selector: 'app-profile-widget',
  templateUrl: './profile-widget.component.html',
  styleUrls: ['./profile-widget.component.scss'],
})
export class ProfileWidgetComponent implements OnInit {

  patients: any = [];
  registerId: any;
  patientId: any;
  appointId: any;
  role = [
    {
      user: 'senior_doctor',
      route: 'doctor'
    },
    {
      user: 'lab_technician',
      route: 'labTechnician'
    }
  ]
  @Output() public routepath = new EventEmitter<{}>();

  constructor(public router: Router, private service: DrpatilService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.registerId = params['id'];
      this.patientId = params['pid'];
      this.appointId = params['appointId'];
    });
    this.getPatient();
  }

  getPatient() {
    this.service.getPatientById(this.registerId).subscribe(
      (res) => {
        res.data.patients.forEach((patient, index) => {
          const payload = {
            registerId: res?.data?.registrationId,
            id: patient?.patientId,
            name: patient?.name.first + ' ' + patient?.name.last,
            phone: patient?.contact?.primaryPhone?.number,
            maritalStatus: patient?.marital_status,
            gender: patient?.sex,
            cycle: res?.data?.currentCycle?.cycle,
            cycleId: res?.data?.currentCycle?._id,
            age: this.checkAge(patient?.dob),
            image: (patient?.imageId) ? this.getImagesPatient(patient?.imageId) : 'assets/profile-icon-common.png',
            appointmentId: this.appointId
          }
          this.patients.push(payload);
        });
        this.patients.forEach(element => {
          if (element.id === this.patientId) {
            this.service.setPatientDetail(element);
          }
        });
      },
      (err) => {
        if (err.status == 400) {
          this.service.openSnackBar(err.error.message);
        } else {
          this.service.openSnackBar('ERR_INTERNET_DISCONNECTED');
        }
      }
    );
  }

  checkAge(data) {
    const convertAge = new Date(data);
    const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    const showAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
    return showAge;
  }

  getImagesPatient(value) {
    return new Promise((resolve, reject) => {
      this.service.getImage(value).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject();
        }
      )
    });
  }

  setPatient(data) {
    const role = JSON.parse(localStorage.getItem('role'));
    // const parentRoute = this.role.filter(res => res.user === role[0]);
    const parentRoute = this.service.role.filter(res => res.user === role[0]);
    this.service.setPatientDetail(data);
    this.router.navigate([`${parentRoute[0].route}/userDetails/${data?.registerId}/${data?.id}/dashboard/${data.appointmentId}`]);
  }

  routePatient(data) {
    this.routepath.emit(data);
  }
}
