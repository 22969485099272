import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { ConfirmDialogData } from 'src/app/confirm.dialog-data';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,public router:Router) { }

  

  ngOnInit(): void {
  }
  route(link) {
    this.router.navigateByUrl(link);
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  checkType(value, data): boolean {
    return data.some((data) => data['link'] === value);
  }

}
