import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-drpatilsclinic-checkbox',
  templateUrl: './drpatilsclinic-checkbox.component.html',
  styleUrls: ['./drpatilsclinic-checkbox.component.scss'],
})
export class DrpatilsclinicCheckboxComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() name = '';
  @Input() idd = 'id';
  @Input() checkboxData;
  @Input() ifSelectedData = false;
  @Output() public getData = new EventEmitter<string>();
  @Input() selectedData: any = [];

  constructor() { }

  ngOnChanges(): void {
    if (this.ifSelectedData == true) {
      this.getfunction();
    }
  }

  ngOnInit(): void {
    if (this.ifSelectedData == true) {
      this.getfunction();
    }
  }

  getfunction() {
    if (this.checkboxData.length > 0) {
      this.checkboxData.forEach((element) => {
        if (element.selected == true) {
          const payload = {
            checked: true,
          };
          element['target'] = payload;
          this.typesData(element, element.name);
        }
      });
    }
  }

  typesData(e, value) {
    if (e.target.checked == true) {
      this.selectedData.push(value);
    } else {
      const data = this.selectedData.indexOf(value);
      this.selectedData.splice(data, 1);
    }
    this.getData.emit(this.selectedData);
  }
}
