import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-drpatilsclinic-table-report-modal',
  templateUrl: './drpatilsclinic-table-report-modal.component.html',
  styleUrls: ['./drpatilsclinic-table-report-modal.component.scss'],
})
export class DrpatilsclinicTableReportModalComponent implements OnInit {
  @Input() modalBtnId = '';
  @Input() tableWidth = '';
  @Input() tableHeadalign = '';
  @Input() tableBodyalign = '';
  @Input() modalName = '';
  @Input() tableConfig;
  @Input() tableContent = [];
  @Input() ifRow:boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
