import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { data } from 'jquery';

@Component({
  selector: 'app-drpatilsclinic-table',
  templateUrl: './drpatilsclinic-table.component.html',
  styleUrls: ['./drpatilsclinic-table.component.scss'],
})
export class DrpatilsclinicTableComponent implements OnInit,OnChanges {
  @Input() type = '';
  @Input() tableConfig;
  @Input() tableContent = [];
  @Input() noDataMessage = '';
  @Input() route: any;
  @Input() page: any;
  @Input() isMerge = false;
  @Input() Appoinment = false;
  @Input() DrAppoinment = false;
  @Input() showView = true;
  @Input() showEdit = true;
  @Input() showDelete = false;
  @Input() showData = true;
  @Input() idd = '';
  @Input() idModal = '';
  @Input() modalName = '';
  @Input() modalMessage = '';
  @Input() iconClass = '';
  @Output() public viewAction = new EventEmitter<{}>();
  @Output() public editAction = new EventEmitter<{}>();
  @Output() public deleteAction = new EventEmitter<{}>();
  @Output() public confirmYes = new EventEmitter();
  @Input() showPagination = false;
  @Input() currentPage: number;
  pageEvent;
  @Output() currentPageIndex = new EventEmitter();
  pageOffset: any;
  @Input() pageSize = 5;
  @Output() pagination = new EventEmitter();
  @Input() totalCount = 0;

  sorted: boolean = false;
  completed : any;
  cancelled : any;

  makeReadonly : boolean;
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.tableContent,"tableContent")
  }

  ngOnInit(): void {

   }

  onViewClicked = (data) => {
    this.viewAction.emit(data);
    console.log('Eye icon', data);

    // if(data == this.completed || data == this.cancelled) {
    //   this.makeReadonly = true;
    // }
  };

  onEditClicked = (data) => {
    // console.log(this.tableContent,'tableContent');


    this.editAction.emit(data);
  };

  onDeleteClicked = (data) => {
    this.deleteAction.emit(data);

  };

  onClickYes() {
    this.confirmYes.emit();
    document.getElementById("closeModal").click();
  };

  pageChange(e): void {
    this.currentPageIndex.emit(e.pageIndex);
    this.pageOffset = this.paginationOffset(e['pageIndex'], e['pageSize']);
    const page = {
      limit: this.pageSize,
      offset: this.pageOffset
    };
    this.pagination.emit(page);
  }


  paginationOffset(currentPage, itemsPerPage): number {
    let offset = (currentPage) * itemsPerPage + 1;
    return (offset = offset < 0 ? offset : offset - 1);
  }

  sort(colName, boolean) {
    // if (boolean == true) {
    //   this.tableContent.sort((a, b) => a[colName] < b[colName] ? 1 : a[colName] > b[colName] ? -1 : 0)
    //   this.sorted = !this.sorted
    // }
    // else {
    //   this.tableContent.sort((a, b) => a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0)
    //   this.sorted = !this.sorted
    // }
  }

  checkType(val): boolean {
    return typeof val === 'object';
  }

}
