import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DrpatilService } from 'src/app/shared/providers/drpatil.service';

@Component({
  selector: 'app-drpatilclinics-login',
  templateUrl: './drpatilclinics-login.component.html',
  styleUrls: ['./drpatilclinics-login.component.scss'],
})
export class DrpatilclinicsLoginComponent implements OnInit {
  loginForm: FormGroup;
  hide = true;
  submitting: boolean;

  constructor(
    private fb: FormBuilder,
    private service: DrpatilService,
    private router: Router
  ) { }

  ngOnInit(): void {
    localStorage.clear();
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
    console.log('updated on 24-04-2023')
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.loginSubmit();
      // rest of your code
    }
  }

  loginSubmit() {
    this.hide = true;
    this.submitting = true;
    this.loginForm.markAllAsTouched();
    if (this.loginForm.invalid) {
      this.submitting = false;
      return;
    }
    this.service.login(this.loginForm.value).subscribe(
      (res) => {
        if (res) {
          this.submitting = false;
          const helper = new JwtHelperService();
          const decoded = helper.decodeToken(res.data.token);
          localStorage.setItem(
            'userName',
            res.data.name.first + ' ' + res.data.name.last
          );
          if (res.data.imageId) {
            localStorage.setItem('imageId', res.data.imageId);
          }
          localStorage.setItem('userId', res.data.userId);
          localStorage.setItem('role', JSON.stringify(res.data.roles));
          localStorage.setItem('token', res.data.token);
          this.service.openSnackBar(res.message);
          if (res.data.roles[0] == 'senior_doctor') {
            this.router.navigate(['/doctor/patientAppointment']);
          } else if (res.data.roles[0] == 'receptionist') {
            this.router.navigate(['/receptionist/listOfPatients']);
          } else if (res.data.roles[0] == 'system_admin') {
            this.router.navigate(['/systemAdmin/userRegistration']);
          } else if (res.data.roles[0] == 'lab_technician') {
            this.router.navigate(['/labTechnician/patientAppointment']);
          }
        }
      },
      (err) => {
        this.submitting = false;
        if (err.status == 400) {
          this.service.openSnackBar(err.error.message);
        } else {
          this.service.openSnackBar('ERR_INTERNET_DISCONNECTED');
        }
      }
    );
  }
}
