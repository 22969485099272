import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';
import decode from 'jwt-decode';
@Injectable()
export class RoleGuardService implements CanActivate {
    constructor(public auth: AuthService, public router: Router) { }
    canActivate(route: ActivatedRouteSnapshot): boolean {
        // this will be passed from the route config
        // on the data property
        const expectedRole = route.data.expectedRole;
        const token = localStorage.getItem('token');
        const role = JSON.parse(localStorage.getItem('role'));
        // decode the token to get its payload
        const tokenPayload = decode(token);
        if (
            !this.auth.isAuthenticated() ||
            role[0] !== expectedRole[0]
        ) {
            this.router.navigate(['login']);
            return false;
        }
        return true;
    }
}