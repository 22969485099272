import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
    constructor(private router: Router) { }

    public isAuthenticated(): boolean {
        const token = localStorage.getItem('token');
        if (token) {
            const helper = new JwtHelperService();
            try {
                if (helper.decodeToken(token)) {
                    return !helper.isTokenExpired(token);
                } else {
                    this.router.navigate(['/login']);
                }
            } catch {
                this.router.navigate(['/login']);
            }
        }
    }
}