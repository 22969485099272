import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { DrpatilclinicsLoginComponent } from './pages/drpatilclinics-login/drpatilclinics-login.component';
import { CustomDateAdapter } from './shared/directives/custom.date.adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DndDirective } from './shared/directives/dnd';
import { AuthService } from './shared/providers/auth/auth.service';
import { RoleGuardService } from './shared/providers/auth/role-guard.service';
import { HttpTokenInterceptor } from './shared/providers/httpinterceptor/http-interceptor.service';
import { SharedModule } from './shared/shared.module';
import { ConfirmComponent } from './dialog/confirm/confirm.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDirective } from './drag.directive';

// import { DragDirective } from './drag.directive';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'three-strings',
  blur: 15,
  delay: 0,
  fastFadeOut: true,
  fgsColor: '#ffffff',
  fgsPosition: 'center-center',
  fgsSize: 70,
  fgsType: 'three-strings',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 120,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(8,155,171,0.99)',
  pbColor: '#ffffff',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 300,
};

@NgModule({
  declarations: [
    AppComponent,
    DrpatilclinicsLoginComponent,
    DndDirective,
    ConfirmComponent,
    DragDirective,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    MatTooltipModule,
  ],
  providers: [
    AuthService,
    RoleGuardService,
    DatePipe,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
