<div class="side-app pb-0">
    <div *ngIf="!imageName && !profileView">
        <div class="col-lg-12">
            <mat-tab-group mat-align-tabs="start" (selectedTabChange)="tabChange($event)">
                <mat-tab label="Browse">
                    <div class="py-3 popup-height">
                        <div class="uploadSection" (dragover)="onDragover($event)" (dragleave)="onDragleave($event)"
                            (drop)="onDrop($event)" draggable="true" dropzone>
                            <!--  appDnd (fileDropped)="onFileDropped($event)"       appDrag (files)="fileDropped($event)"-->
                            <div class="center">
                                <input type="file" #fileDropRef id="{{uploadId}}" accept=".png, .jpg, .jpeg"
                                    (change)="fileBrowseHandler($event)">
                                <i class="fa fa-upload" aria-hidden="true"></i>
                                <h3 class="headers mt-3">Drag and drop file here</h3>
                                <h3>or</h3>
                                <label for="{{uploadId}}">Browse for file</label>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Upload">
                    <div class="py-3 popup-height">
                        <ng-container *ngIf="showCamera">
                            <webcam [height]="310" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                                [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                                [videoOptions]="videoOptions" (cameraSwitched)="cameraWasSwitched($event)"
                                (initError)="handleInitError($event)">
                            </webcam>
                            <label class="label" (click)="triggerSnapshot()"><i class="icofont-camera-alt"></i></label>
                        </ng-container>
                    </div>
                </mat-tab>
            </mat-tab-group>
            <div class="row justify-content-end">
                <div class="col-md-3 mb-3">
                    <button type="button" class="btn-danger" data-dismiss="modal"
                        (click)="closeDialog(false)">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="imageName && !profileView">

        <div class="col-lg-12">
            <image-cropper [imageChangedEvent]="imageChangedEvent" [imageBase64]="dropBase64"
                [maintainAspectRatio]="false" [aspectRatio]="4 / 3" format="png" [resizeToWidth]="256"
                (imageCropped)="imageCropped($event)">
            </image-cropper>
        </div>
        <div class="row justify-content-end">
            <div class="col-md-3 mb-3">
                <button type="button" class="btn-danger" data-dismiss="modal" (click)="deleteImage()">Back</button>
            </div>
            <div class="col-md-3 mb-3">
                <button class="btn-default" [disabled]="submitting" (click)="uploadFiles()">
                    <div *ngIf="!submitting">Save</div>
                    <i *ngIf="submitting" class="fa fa-circle-o-notch fa-spin"
                        style="font-size: 18px; color: white;"></i>
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="profileView">
    <div class="col-lg-12">
        <div class="imageReslution mb-3">
            <img [src]="profileView || 'assets/profile-icon-common.png'">
        </div>
        <div class="row justify-content-end">
            <div class="col-md-3 mb-3">
                <button type="button" class="btn-danger" data-dismiss="modal" (click)="deleteImage()">Delete</button>
            </div>
            <div class="col-md-3 mb-3">
                <button type="button" class="btn-default" data-dismiss="modal"
                    (click)="closeDialog(true)">Close</button>
            </div>
        </div>
    </div>
</div>