<mat-sidenav-container class="sidenav-container">
  <mat-sidenav class="sidenav" *ngIf="!router.url.includes('doctor/userDetails') || router.url.includes('dashboard')"
    fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false" #drawer>
    <mat-toolbar><img src="../../assets/logo.png"></mat-toolbar>
    <mat-nav-list class="navIcon">
      <ng-container *ngFor="let navlink of selectedLinks">
        <span *ngIf="navlink.children && navlink.children.length > 0">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header [ngClass]="{'active-link': checkType(router?.url, navlink.children) }">
                <mat-panel-title>
                  <span><i [ngClass]="navlink?.icon"></i>{{ navlink?.name }}</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span *ngFor="let child of navlink.children" class="fs">
                <a style=" padding: 0px 33px;" mat-list-item
                   [ngClass]="{'active-link': (router?.url?.includes(child?.link)) }" [id]="child?.id"
                   (click)="route(child?.link)">
                  <i [ngClass]="child?.icon"></i>
                  <span>{{ child?.name }}</span>
                </a>
              </span>
            </mat-expansion-panel>
          </mat-accordion>
        </span>
        <span *ngIf="!navlink.children || navlink.children.length === 0" class="main">
          <a mat-list-item [ngClass]="{'active-link': (router?.url?.includes(navlink?.link)) }" [id]="navlink?.id"
             (click)="route(navlink?.link)"><i [ngClass]="navlink?.icon"></i><span>{{ navlink?.name }}</span></a>
        </span>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content style="height: 100vh !important">
    <mat-toolbar color="primary" class="menu-bar">
      <div *ngIf="router.url.includes('dashboard')">
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
          *ngIf="isHandset$ | async">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
      </div>
      <nav class="navbar w-100 p-0">
        <div class="navbar-brand" style="width: 218px;">
          <a href="/doctor/patientAppointment"
            *ngIf="router.url.includes('doctor/userDetails') && !router.url.includes('dashboard')">
            <img src="../../assets/logo.png">
          </a>
        </div>
        <form class="form-inline">
          <div class="caption pt-3 text-right">
            <h6 class="mb-2 line-height headerFontColor">{{userName | titlecase}}</h6>
            <!-- <span class="f12">Online</span> -->
          </div>
          <img *ngIf="userImage?.__zone_symbol__value" [matMenuTriggerFor]="menu"
            [src]="userImage?.__zone_symbol__value?.data" class="size50 ml-3" alt="user">
          <img *ngIf="!userImage?.__zone_symbol__value?.data" [matMenuTriggerFor]="menu" [src]="userImage"
            class="size50 ml-3" alt="user">
          <mat-menu #menu="matMenu">
            <!-- <button mat-menu-item (click)="logout()">
              <div class="rounded-circle iq-card-icon bgRed mr-2"><i class="icofont-logout"></i></div><span
                style="font-family: poppins;">Logout</span>
            </button> -->

            <!-- New Code -->
            <button mat-menu-item (click)="openDialog()">
              <div class="rounded-circle iq-card-icon bgRed mr-2"><i class="icofont-logout"></i></div><span
                style="font-family: poppins;">Logout</span>
            </button>
            <!-- New Code -->

          </mat-menu>
        </form>
      </nav>
    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
