import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DrpatilclinicsLoginComponent } from './pages/drpatilclinics-login/drpatilclinics-login.component';
import { RoleGuardService } from './shared/providers/auth/role-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: DrpatilclinicsLoginComponent,
  },
  {
    path: 'receptionist',
    loadChildren: () => import('./users/receptionist/receptionist.module').then(m => m.ReceptionistModule),
    canActivate: [RoleGuardService],
    data: {
      expectedRole: ['receptionist'],
    }
  },
  {
    path: 'doctor',
    loadChildren: () => import('./users/senior-doctor/senior-doctor.module').then(m => m.SeniorDoctorModule),
    canActivate: [RoleGuardService],
    data: {
      expectedRole: ['senior_doctor'],
    }
  },
  {
    path: 'systemAdmin',
    loadChildren: () => import('./users/system-admin/system-admin.module').then(m => m.SystemAdminModule),
    canActivate: [RoleGuardService],
    data: {
      expectedRole: ['system_admin'],
    }
  },
  {
    path: 'labTechnician',
    loadChildren: () => import('./users/lab-technician/lab-technician.module').then(m => m.LabTechnicianModule),
    canActivate: [RoleGuardService],
    data: {
      expectedRole: ['lab_technician'],
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
