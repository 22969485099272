import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DrpatilService } from 'src/app/shared/providers/drpatil.service';

@Component({
  selector: 'app-appointment-widget',
  templateUrl: './appointment-widget.component.html',
  styleUrls: ['./appointment-widget.component.scss'],
})
export class AppointmentWidgetComponent implements OnInit {

  @Output() public getAppointId = new EventEmitter<string>();
  appointmentData: any = [];
  patientId: any;
  registerId: any;
  role:any;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private service: DrpatilService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.registerId = params['id'];
      this.patientId = params['pid'];
      if (this.patientId) {
        this.getPatientAppointment();
        this.role = JSON.parse(localStorage.getItem('role'))[0];
      }
    });
  }

  getPatientAppointment() {
    this.appointmentData = []
    this.service
      .getPatientAppointments(this.patientId)
      .subscribe(
        (res) => {
          console.log('res', res)
          res.data.data.forEach((element, index) => {
            if (index < 5) {
              const payload = {
                appointId: element._id,
                date: this.datePipe.transform(
                  element.startDate,
                  'dd-MM-yyyy'
                ),
                doctor: element.doctors[element.doctors.length - 1]?.name?.first + ' ' + element.doctors[element.doctors.length - 1]?.name?.last,
                reason: element.reason,
              };
              this.appointmentData.push(payload);
            }
          });
        },
        (err) => {
          if (err.status == 400) {
            this.service.openSnackBar(err.error.message);
          } else {
            this.service.openSnackBar('ERR_INTERNET_DISCONNECTED');
          }
        }
      );
  }

  sendId(value) {
    this.getAppointId.emit(value);
  }

  redirectTo() {
    this.router.navigate([
      'doctor/viewAppointments',
      this.registerId
    ]);
  }
}
