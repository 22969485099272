<i class="fa fa-plus pl-3" id="{{btnId}}" (click)="openDialog()"></i>

<button type="button" class="btn btn-primary" id="{{idModal}}" [hidden]="true" data-toggle="modal"
  attr.data-target="#modal{{idd}}" data-backdrop="false" data-keyboard="false">
  Launch demo modal
</button>

<div class="modal fade" attr.id="modal{{idd}}" tabindex="-1" role="dialog" aria-spanledby="exampleModalspan"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="col-lg-12">
        <div class="modal-header">
          <label class="mb-0 modalHeaders">{{modalName}}</label>
        </div>
      </div>
      <div class="modal-body">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="row mb-3">
                <div class="col-lg-4 col-md-6" *ngFor="let data of checkboxData">
                  <mat-checkbox #checkboxes (change)="typesData($event, data.name)" [checked]="data.selected">
                    <span class="f13">{{data.name}}</span>
                  </mat-checkbox>
                </div>
              </div>
              <div class="row" [formGroup]="form" *ngIf="showTextBox">
                <div class="col-lg-12 mb-3" *ngIf="IfformGName">
                  <mat-form-field class="example-full-width">
                    <mat-label>Details</mat-label>
                    <textarea matInput formControlName="{{textControlName}}" rows="2" id="{{textidd}}"
                      name="{{name}}"></textarea>
                  </mat-form-field>
                </div>
                <div class="col-lg-12 mb-3" formGroupName="{{formGName}}" *ngIf="!IfformGName">
                  <mat-form-field class="example-full-width">
                    <mat-label>Any other test</mat-label>
                    <textarea matInput formControlName="{{textControlName}}" rows="2" id="{{textidd}}"
                      name="{{name}}"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="row justify-content-end">
                <div class="col-lg-2 col-md-3">
                  <button type="button" class="btn-default" (click)="onClickLeft()">
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>