import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import { Router } from '@angular/router';
import { DrpatilService } from '../drpatil.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private service: DrpatilService
  ) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    const authReq = req.clone({
      setHeaders: {
        token: token || ''
      }
    });
    return next.handle(authReq).catch((error, caught) => {
      if (error.status === 403 || error.status === 401) {
        this.router.navigate(['/login']);
        this.service.openSnackBar('Sorry, you are not authorized!');
        return Observable.throw(error);
      }
      if (error.status === 502) {
        this.service.openSnackBar('server temporarily down!');
        return Observable.throw(error);
      }
      return Observable.throw(error);
    }) as any;
  }

}
