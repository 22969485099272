import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ConfirmComponent } from '../dialog/confirm/confirm.component';
import { ConfirmDialogData } from '../confirm.dialog-data';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog : MatDialog) { }
  confirmDialog(data : ConfirmDialogData) : Observable<boolean> {
    return this.dialog.open(ConfirmComponent, {
      data,
      width: '400px',
      disableClose : true,
    }).afterClosed();
  }
}
