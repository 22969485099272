import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { DrpatilsclinicCheckboxComponent } from "./components/drpatilsclinic-checkbox/drpatilsclinic-checkbox.component";
import { DrpatilsclinicRadioButtonComponent } from './components/drpatilsclinic-radio-button/drpatilsclinic-radio-button.component';
import { DrpatilsclinicTableComponent } from './components/drpatilsclinic-table/drpatilsclinic-table.component';
import { DrpatilsclinicTableReportModalComponent } from './components/drpatilsclinic-table-report-modal/drpatilsclinic-table-report-modal.component';
import { MainNavComponent } from "./main-nav/main-nav.component";
import { MatModule } from "./mat.module";
import { DrpatilsclinicMatDialogComponent } from "./components/drpatilsclinic-mat-dialog/drpatilsclinic-mat-dialog.component";
import { Ng5SliderModule } from 'ng5-slider';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { ImageCropperModule } from "ngx-image-cropper";
import { WebcamModule } from "ngx-webcam";
import { AppointmentWidgetComponent } from "../users/senior-doctor/pages/drpatilsclinic-patient-view/components/appointment-widget/appointment-widget.component";
import { ProfileWidgetComponent } from "../users/senior-doctor/pages/drpatilsclinic-patient-view/components/profile-widget/profile-widget.component";
import { DrpatilsclinicPatImageUploadComponent, UploadPopup } from "./components/drpatilsclinic-pat-image-upload/drpatilsclinic-pat-image-upload.component";

@NgModule({
    declarations: [
        MainNavComponent,
        DrpatilsclinicMatDialogComponent,
        DrpatilsclinicCheckboxComponent,
        DrpatilsclinicRadioButtonComponent,
        DrpatilsclinicTableComponent,
        DrpatilsclinicTableReportModalComponent,
        ProfileWidgetComponent,
        AppointmentWidgetComponent,
        DrpatilsclinicPatImageUploadComponent,
        UploadPopup
    ],
    imports: [
        CommonModule,
        MatModule,
        Ng5SliderModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaterialTimepickerModule,
        WebcamModule,
        ImageCropperModule,
    ],
    exports: [
        MainNavComponent,
        DrpatilsclinicMatDialogComponent,
        DrpatilsclinicCheckboxComponent,
        DrpatilsclinicRadioButtonComponent,
        DrpatilsclinicTableComponent,
        DrpatilsclinicTableReportModalComponent,
        DrpatilsclinicPatImageUploadComponent,
        UploadPopup,
        MatModule,
        Ng5SliderModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaterialTimepickerModule,
        WebcamModule,
        ImageCropperModule,
        ProfileWidgetComponent,
        AppointmentWidgetComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})

export class SharedModule { }
