import { Component, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { DrpatilService } from '../providers/drpatil.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
})
export class MainNavComponent {
  id = '1';
  adminName = 'Dhanya';
  @ViewChild('drawer') drawer: MatSidenav;
  public now: Date = new Date();
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  showMenu: boolean;
  userName: string;
  navlinks: any = [
    {
      role: 'senior_doctor',
      links: [
        {
          name: 'Appointments',
          link: '/doctor/patientAppointment',
          id: '1',
          icon: 'icofont-ui-calendar',
        },
        {
          name: 'Patient List',
          icon: 'icofont-users-alt-2',
          id: '2',
          link: '/doctor/listOfPatients',
          sub_link: '/doctor/viewPatient'
        },
      ]
    },
    {
      role: 'receptionist',
      links: [{
        name: 'Patient List',
        icon: 'icofont-users-alt-2',
        children: [
          {
            id: '1',
            name: 'Add Patient',
            icon: 'icofont-rounded-right',
            link: '/receptionist/registration',
          },
          {
            id: '2',
            name: 'All Patients',
            icon: 'icofont-rounded-right',
            link: '/receptionist/listOfPatients',
          },
          {
            id: '3',
            name: 'Edit Patient',
            icon: 'icofont-rounded-right',
            link: '/receptionist/editPatient',
          },
        ],
      },
        {
          name: 'Appointments',
          link: '/receptionist/appointments',
          id: '4',
          icon: 'icofont-ui-calendar',
        }]
    },
    {
      role: 'system_admin',
      links: [
        {
          name: 'Users',
          icon: 'icofont-users-alt-1',
          children: [
            {
              id: '1',
              name: 'Add User',
              icon: 'icofont-rounded-right',
              link: '/systemAdmin/userRegistration',
            },
            {
              id: '2',
              name: 'All Users',
              icon: 'icofont-rounded-right',
              link: '/systemAdmin/listOfUsers',
            },
            {
              id: '3',
              name: 'Edit User',
              icon: 'icofont-rounded-right',
              link: '/systemAdmin/editUser',
            },
          ],
        },
        {
          name: 'Services',
          link: '/systemAdmin/services',
          id: '2',
          icon: 'icofont-medical-sign',
        },
      ]
    },
    {
      role: 'lab_technician',
      links: [
        {
          name: 'Appointments',
          link: '/labTechnician/patientAppointment',
          id: '1',
          icon: 'icofont-ui-calendar',
        } ]
    }
  ]
  userImage: any;
  selectedLinks = []

  constructor(
    private breakpointObserver: BreakpointObserver,
    public router: Router,
    public activatedroute: ActivatedRoute,
    private service: DrpatilService,
    // I create new service
    private dialogService : DialogService,
    // 
  ) {
    this.now = new Date();
    this.userName = localStorage.getItem('userName');
    this.userImage = localStorage.getItem('imageId')
      ? this.getImagesPatient(localStorage.getItem('imageId'))
      : 'assets/profile-icon-common.png';
    const role = JSON.parse(localStorage.getItem('role'));
    const filteredlinks = this.navlinks.filter(res => res.role === role[0]);
    this.selectedLinks = filteredlinks[0].links
    console.log('this.selectedLinks', this.selectedLinks)
  }

  getImagesPatient(value) {
    return new Promise((resolve, reject) => {
      this.service.getImage(value).subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject();
        }
      );
    });
  }

  route(link) {
    this.router.navigateByUrl(link);
  }
  
  
  // My Code

  openDialog() {
    this.dialogService.confirmDialog({
      title : 'Are you sure?',
      message : 'Are you sure want to do this?',
      confirmText : 'Yes',
      cancelText : 'No'
    });
  }
//  My Code end
  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  checkType(value, data): boolean {
    return data.some((data) => data['link'] === value);
  }
}



