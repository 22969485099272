<div class="table-responsive">
  <table class="table">
    <thead>
      <tr class="tableRow">
        <th *ngFor="let columnHead of tableConfig?.columns" (click)="sort(columnHead.dataProperty, sorted)">{{
          columnHead?.title
          }}</th>
        <ng-container *ngIf="(showView || showEdit || showDelete) && showData">
          <th *ngIf="showView"></th>
          <th *ngIf="showEdit"></th>
          <th *ngIf="showDelete"></th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="showData">
        <ng-container *ngIf="!isMerge && !Appoinment && !DrAppoinment">
          <tr *ngFor="let columnData of tableContent">
            <td *ngFor="let columnHead of tableConfig?.columns"
              [ngClass]="columnHead?.alignment ? columnHead?.alignment:''"
              [style.color]="columnHead?.dataProperty === 'status' ? columnData?.statusColor : ''">
              <i class="f18 pr-2" *ngIf="columnData[columnHead?.dataProperty]?.icon"
                [style.color]="columnData[columnHead?.dataProperty].iconColor"
                [ngClass]="columnData[columnHead?.dataProperty].icon" aria-hidden="true">
              </i>
              <img *ngIf="columnData[columnHead?.dataProperty]?.image"
                [src]="columnData[columnHead?.dataProperty]?.image.__zone_symbol__value?.data ? columnData[columnHead?.dataProperty]?.image.__zone_symbol__value?.data : columnData[columnHead?.dataProperty]?.image"
                class="avatar rounded-circle mr-2" alt="profile-image">
              <i *ngIf="columnHead?.dataProperty === 'status'" [style.color]="columnData?.statusColor"
                [ngClass]="columnData?.statusIcon" aria-hidden="true"></i>
              <span *ngIf='!checkType(columnData[columnHead?.dataProperty])'>
                {{columnData[columnHead?.dataProperty] }}
              </span>
              <span *ngIf='checkType(columnData[columnHead?.dataProperty])'>
                {{columnData[columnHead?.dataProperty].name }}
              </span>
            </td>
            <td (click)="onViewClicked(columnData)" class="viewStyle text-center" *ngIf="showView"><i
                class="fas fa-eye"></i></td>
            <td (click)="onEditClicked(columnData)" *ngIf="showEdit" class="cp text-center"><i
                class="icofont-ui-edit"></i></td>
            <td (click)="onDeleteClicked(columnData)" *ngIf="showDelete" class="cp text-center"><i
                class="icofont-ui-delete" data-toggle="modal" attr.data-target="#modal{{idd}}" id="{{idModal}}"
                data-backdrop="false"></i></td>
          </tr>
        </ng-container>
        <ng-container *ngIf="isMerge && !Appoinment && !DrAppoinment">
          <tr *ngFor="let data of tableContent">
            <td *ngIf="data?.rowspan >1" [attr.rowspan]="data?.rowspan">{{data.id}}</td>
            <td *ngIf="data?.rowspan == 1">{{data.id}}</td>
            <td>{{data.patientId}}</td>
            <td><img *ngIf="data.name?.image"
                [src]="data.name?.image.__zone_symbol__value?.data ? data.name?.image.__zone_symbol__value?.data : data.name?.image"
                class="avatar rounded-circle mr-2" alt="profile-image">{{data?.name?.name}}</td>
            <td class="text-right">{{data.age}}</td>
            <td>{{data.sex}}</td>
            <td>{{data.email}}</td>
            <td class="text-right">{{data.phoneNumber}}</td>
            <td class="text-right">{{data.registeredDate}}</td>
            <ng-container *ngIf="data?.rowspan>1">
              <td [attr.rowspan]="data?.rowspan" (click)="onEditClicked(data)" *ngIf="showEdit" class="cp text-center">
                <i class="icofont-ui-edit"></i>
              </td>
              <td [attr.rowspan]="data?.rowspan" (click)="onViewClicked(data)" class="viewStyle text-center"
                *ngIf="showView">
                <i class="fas fa-eye"></i>
              </td>
              <td (click)="onDeleteClicked(data)" [attr.rowspan]="data?.rowspan" *ngIf="showDelete" class="cp"><i
                  class="icofont-ui-delete" data-toggle="modal" attr.data-target="#modal{{idd}}" id="{{idModal}}"
                  data-backdrop="false"></i></td>
            </ng-container>
            <ng-container *ngIf="data?.rowspan == 1">
              <td (click)="onViewClicked(data)" class="viewStyle text-center" *ngIf="showView"><i
                  class="fas fa-eye"></i></td>
              <td (click)="onEditClicked(data)" *ngIf="showEdit" class="cp text-center"><i class="icofont-ui-edit"></i>
              </td>
              <td (click)="onDeleteClicked(data)" *ngIf="showDelete" class="cp text-center"><i class="icofont-ui-delete"
                  data-toggle="modal" attr.data-target="#modal{{idd}}" id="{{idModal}}"></i>
              </td>
            </ng-container>
          </tr>
        </ng-container>
        <ng-container *ngIf="Appoinment">
          <tr *ngFor="let data of tableContent">
            <td *ngIf="data?.rowspan>1" [attr.rowspan]="data?.rowspan">{{data.registerId}}</td>
            <td *ngIf="data?.rowspan == 1">{{data.registerId}}</td>
            <td>{{data.patientId}}</td>
            <td><img *ngIf="data.name?.image"
                [src]="data.name?.image.__zone_symbol__value?.data ? data.name?.image.__zone_symbol__value?.data : data.name?.image"
                class="avatar rounded-circle mr-2" alt="profile-image">{{data?.name?.name}}</td>
            <td>{{data.email}}</td>
            <td class="text-right">{{data.phoneNumber}}</td>
            <td *ngIf="data?.rowspan == 1">{{data.doctor}}</td>
            <td class="text-right" *ngIf="data?.rowspan == 1">{{data.startDate}}</td>
            <td class="text-right" *ngIf="data?.rowspan == 1">{{data.endDate}}</td>
            <ng-container *ngIf="data?.rowspan>1">
              <td [attr.rowspan]="data?.rowspan">{{data.doctor}}</td>
              <td class="text-right" [attr.rowspan]="data?.rowspan">{{data.startDate}}</td>
              <td class="text-right" [attr.rowspan]="data?.rowspan">{{data.endDate}}</td>
              <td [attr.rowspan]="data?.rowspan" (click)="onViewClicked(data)" class="viewStyle text-center"
                *ngIf="showView">
                <i class="fas fa-eye"></i>
              </td>
              <td [attr.rowspan]="data?.rowspan" (click)="onEditClicked(data)" *ngIf="showEdit" class="cp"><i
                  class="icofont-ui-edit"></i></td>
              <td (click)="onDeleteClicked(data)" [attr.rowspan]="data?.rowspan" *ngIf="showDelete" class="cp"><i
                  class="icofont-ui-delete" data-toggle="modal" attr.data-target="#modal{{idd}}" id="{{idModal}}"
                  data-backdrop="false"></i></td>
            </ng-container>
            <ng-container *ngIf="data?.rowspan == 1">
              <td (click)="onViewClicked(data)" class="viewStyle text-center" *ngIf="showView"><i
                  class="fas fa-eye"></i></td>
              <td (click)="onEditClicked(data)" *ngIf="showEdit" class="cp text-center"><i class="icofont-ui-edit"></i>
              </td>
              <td (click)="onDeleteClicked(data)" *ngIf="showDelete" class="cp text-center"><i class="icofont-ui-delete"
                  data-toggle="modal" attr.data-target="#modal{{idd}}" id="{{idModal}}" data-backdrop="false"></i>
              </td>
            </ng-container>
          </tr>
        </ng-container>
        <ng-container *ngIf="DrAppoinment">
          <tr *ngFor="let data of tableContent">
            <td *ngIf="data?.rowspan>1" [attr.rowspan]="data?.rowspan">{{data.registerId}}</td>
            <td *ngIf="data?.rowspan == 1">{{data.registerId}}</td>
            <td>{{data.patientId}}</td>
            <td><img *ngIf="data.name?.image"
                [src]="data.name?.image.__zone_symbol__value?.data ? data.name?.image.__zone_symbol__value?.data : data.name?.image"
                class="avatar rounded-circle mr-2" alt="profile-image">{{data?.name?.name}}</td>
            <td>{{data.sex}}</td>
            <td>{{data.email}}</td>
            <td class="text-right">{{data.phoneNumber}}</td>
            <td class="text-right" *ngIf="data?.rowspan == 1">{{data.startDate}}</td>
            <td class="text-center" *ngIf="data?.rowspan == 1"><i *ngIf="data?.statusIcon"
                [style.color]="data?.statusColor" [ngClass]="data?.statusIcon" aria-hidden="true"></i><span
                [style.color]="data?.statusColor">{{data.status | titlecase}}</span></td>
            <ng-container *ngIf="data?.rowspan>1">
              <td class="text-right" [attr.rowspan]="data?.rowspan">{{data.startDate}}</td>
              <td class="text-center" [attr.rowspan]="data?.rowspan"><i *ngIf="data?.statusIcon"
                  [style.color]="data?.statusColor" [ngClass]="data?.statusIcon" aria-hidden="true"></i><span
                  [style.color]="data?.statusColor">{{data.status | titlecase}}</span></td>
              <td [attr.rowspan]="data?.rowspan" (click)="onViewClicked(data)" class="viewStyle text-center"
                *ngIf="showView">
                <i class="fas fa-eye"></i>
              </td>
              <td [attr.rowspan]="data?.rowspan" (click)="onEditClicked(data)" *ngIf="showEdit" class="cp"><i
                  class="icofont-ui-edit"></i></td>
              <td (click)="onDeleteClicked(data)" [attr.rowspan]="data?.rowspan" *ngIf="showDelete" class="cp"><i
                  class="icofont-ui-delete" data-toggle="modal" attr.data-target="#modal{{idd}}" id="{{idModal}}"
                  data-backdrop="false"></i></td>
            </ng-container>
            <ng-container *ngIf="data?.rowspan == 1">
              <td (click)="onViewClicked(data)" class="viewStyle text-center" *ngIf="showView"><i
                  class="fas fa-eye"></i></td>
              <td (click)="onEditClicked(data)" *ngIf="showEdit" class="cp text-center"><i class="icofont-ui-edit"></i>
              </td>
              <td (click)="onDeleteClicked(data)" *ngIf="showDelete" class="cp text-center"><i class="icofont-ui-delete"
                  data-toggle="modal" attr.data-target="#modal{{idd}}" id="{{idModal}}" data-backdrop="false"></i>
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </ng-container>
      <tr *ngIf="!showData">
        <td [attr.colspan]="tableConfig?.columns.length" class="text-center">{{noDataMessage}}</td>
      </tr>
      <tr>
    </tbody>
  </table>
</div>
<mat-paginator *ngIf="showPagination" [pageIndex]="currentPage" (page)="pageEvent = pageChange($event)"
  [showFirstLastButtons]="true" [length]="totalCount" [pageSize]="pageSize">
</mat-paginator>

<div class="modal" attr.id="modal{{idd}}" tabindex="-1" role="dialog" aria-spanledby="exampleModalspan"
  aria-hidden="true">
  <div class="modal-dialog" role="document" style="width:500px;">
    <div class="modal-content formSection w-100">
      <div class="col-lg-12 border-bottom">
        <div class="side-app w-100">
          <label class="headers mb-0">
            <i class="{{iconClass}} iconStyle pr-2"></i>
            {{modalName}}
          </label>
        </div>
      </div>
      <div class="side-app">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12 mb-3">
              <p class="h5 mb-0 text-center">{{modalMessage}}</p>
            </div>
            <div class="col-lg-12">
              <div class="row justify-content-center">
                <div class="col-md-4">
                  <button type="button" class="btn-default" id="closeModal" data-dismiss="modal">Cancel</button>
                </div>
                <div class="col-md-4">
                  <button type="button" class="btn-default btn-danger" (click)="onClickYes()">Delete</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>