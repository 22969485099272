<div class="row" *ngIf="router.url.includes('dashboard')">
    <ng-container *ngFor="let data of patients; let i = index">
        <div class="col-lg-12 cp" (click)="setPatient(data)" [ngClass]="{ 'mt-3' : i > 0 }">
            <div class="pw-shadow" [ngClass]="{'pw-selected' : patientId === data?.id}">
                <div class="col-lg-12 pl-0 pr-0">
                    <div class="doc-profile-bg w-100"
                        [ngClass]="patientId === data?.id ? 'patientActive' : 'bg-primary'" style="height:77px;">
                    </div>
                    <div class="doctor-profile c20">
                        <img [src]="data?.image?.__zone_symbol__value?.data" alt="profile-img"
                            class="avatar-110 img-fluid">
                    </div>
                </div>
                <div class="padding w-100 pw-border">
                    <div class="row">
                        <div class="col-lg-12 c50">
                            <label class="headers">{{data?.name | titlecase}}</label>
                            <div style="font-family: poppins;
                            color: #a09e9e;
                            font-size: 14px;">
                                {{data?.id}}
                            </div>
                            <div style="font-family: poppins;
                            color: #a09e9e;
                            font-size: 14px;">
                                +91 {{data?.phone}}
                            </div>
                        </div>
                        <div class="col-lg-12 text-center py-3" style="font-family: poppins;
                        color: black;
                        font-size: 14px;">
                            <div class="row mt-3">
                                <div class="col-lg-4">
                                    <div class="col-lg-12">
                                        <h1 class="mb-0" style="font-size: 32px; font-family: 'poppins';">{{data.age}}
                                        </h1>
                                    </div>
                                    <div class="col-lg-12 mt-1" style="color: #a09e9e;">
                                        Age
                                    </div>
                                </div>
                                <div class="col-lg-4" style="border-left: 1px solid rgba(0, 0, 0, 0.1);
                                border-right: 1px solid rgba(0, 0, 0, 0.1);">
                                    <div class="col-lg-12">
                                        <i *ngIf="data?.gender === 'Female'" class="fa fa-venus"></i>
                                        <i *ngIf="data?.gender === 'Male'" class="fa fa-mars"></i>
                                    </div>
                                    <div class="col-lg-12 mt-1" style="color: #a09e9e;">
                                        {{data?.gender}}
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="col-lg-12">
                                        <h1 class="mb-0" style="font-size: 32px; font-family: 'poppins';">
                                            {{data?.cycle}}
                                        </h1>
                                    </div>
                                    <div class="col-lg-12 mt-1" style="color: #a09e9e;">
                                        Cycle
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<div class="row" *ngIf="!router.url.includes('dashboard')">
    <ng-container *ngFor="let data of patients">
        <div class="col-lg-12 cp mb-3" (click)="routePatient(data)">
            <div class="pw-shadow" [ngClass]="{'pw-selected' : patientId === data?.id}">
                <div class="col-lg-12 doc-profile-sub-bg w-100"
                    [ngClass]="patientId === data?.id ? 'patientActive' : 'bg-primary'" style="height:110px;">
                    <div class="row h-100">
                        <div class="col-lg-5 flex-center">
                            <img [src]="data?.image?.__zone_symbol__value?.data" alt="profile-img"
                                class="avatar-130 img-fluid">
                        </div>
                        <div class="col-lg-7 flex-center">
                            <div class="avatar-desc row">
                                <div class="col-lg-12 mb-2">
                                    <label class="headers color-white">{{data?.name | titlecase}}</label>
                                </div>
                                <div class="col-lg-12">
                                    <h4 class="mb-0" style="font-family: poppins; font-size: 13px;">
                                        <i style="color: white; 
                                  font-size: 16px" class="fa fa-id-badge pr-1" aria-hidden="true"></i>
                                        {{data?.id}}
                                    </h4>
                                </div>
                                <div class="col-lg-12">
                                    <h4 class="mb-0" style="font-family: poppins; font-size: 13px;">
                                        <i style="color: white; 
                                  font-size: 22px;" class="fa fa-mobile pr-1" aria-hidden="true"></i> +91
                                        {{data?.phone}}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="doc-profile-sub-bg w-100"
                        [ngClass]="patientId === data?.id ? 'patientActive' : 'bg-primary'" style="height:110px;">
                       
                        
                    </div> -->
                </div>
            </div>
        </div>
    </ng-container>
</div>