import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-drpatilsclinic-radio-button',
  templateUrl: './drpatilsclinic-radio-button.component.html',
  styleUrls: ['./drpatilsclinic-radio-button.component.scss'],
})
export class DrpatilsclinicRadioButtonComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() control = '';
  @Input() name = '';
  @Input() radioButtonData;
  @Input() IfformGName = false;
  @Input() formGName = '';
  @Input() idd = '';

  @Output() public getData = new EventEmitter<string>();

  constructor() { }
  
  ngOnChanges(): void {
    this.radioButtonData = this.radioButtonData;
  }

  ngOnInit(): void { }

  onClick(value) {
    this.radioChecked(value, this.radioButtonData)
    this.getData.emit(value);
  }

  radioChecked(data: any, array: any) {
    array.forEach((item: any) => {
      if (item.name === data) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
  }
}
