<div class="col-lg-12">
  <div class="avatar-upload">
    <div class="avatar-edit">
      <div class="iconBorder" (click)="openDialog()"></div>
    </div>
    <div class="avatar-preview">
      <div id="imagePreview{{uploadId}}">
        <img [src]="profileView || 'assets/profile-icon-common.png'" class="imgSet">
      </div>
    </div>
  </div>
</div>
