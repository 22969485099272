import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, RouteConfigLoadStart, Router, RouterEvent } from '@angular/router';
import { DrpatilService } from './shared/providers/drpatil.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'drpatilsclinic';

  constructor(public router: Router, public service: DrpatilService) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    })
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.service.ngxService.start();
    }
    if (event instanceof NavigationEnd) {
      this.service.ngxService.stop();
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.service.ngxService.stop();
    }
    if (event instanceof NavigationError) {
      this.service.ngxService.stop();
    }
  }

}

window.onbeforeunload = function (e) {
  window.onunload = function () {
    window.localStorage.isMySessionActive = "false";
  }
  return undefined;
};

window.onload = function () {
  window.localStorage.isMySessionActive = "true";
};
