import { Router } from '@angular/router';
import { FileHandle } from './file-handle.module';
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { DrpatilService } from 'src/app/shared/providers/drpatil.service';
import * as uuid from 'uuid';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { log } from 'console';

export interface DialogData {
  id: any;
  image: any;
}
@Component({
  selector: 'app-drpatilsclinic-pat-image-upload',
  templateUrl: './drpatilsclinic-pat-image-upload.component.html',
  styleUrls: ['./drpatilsclinic-pat-image-upload.component.scss'],
})
export class DrpatilsclinicPatImageUploadComponent implements OnChanges {
  [x: string]: any;
  @Input() uploadId = '';
  @Output() sendImg = new EventEmitter();
  @Input() imageID: any = '';
  profileView: any = '';

  constructor(private service: DrpatilService, public dialog: MatDialog) {}

  ngOnChanges(): void {
    if (!this.imageID) {
      this.profileView = '';
    } else {
      if (!this.profileView) {
        this.getImages(this.imageID);
      }
    }
  }

  getImages(value) {
    this.service.getImage(value).subscribe((res) => {
      this.profileView = res.data;
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadPopup, {
      panelClass: 'myapp-no-padding-dialog',
      width: '500px',
      data: {
        id: this.uploadId,
        image: this.profileView,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'uploaded') {
        if (result?.data) {
          this.profileView = result?.data?.data;
          this.sendImg.emit(result?.data?.id);
        }
      } else {
        this.profileView = result?.data;
        this.sendImg.emit('');
      }
    });
  }
}

@Component({
  selector: 'upload-popup',
  templateUrl: 'upload-popup.html',
  styleUrls: ['./drpatilsclinic-pat-image-upload.component.scss'],
})
export class UploadPopup implements OnInit {
  @Input() uploadId = '';
  imageUrl: any = '';
  // imageName: string;
  imageName: any;

  imageChangedEvent: any;
  dropBase64: any;
  existingFile: any;
  @Output() sendImg = new EventEmitter();
  profileView: any = '';
  imageData: any;
  submitting: boolean = false;

  imageDropEvent: any;
  showCamera=0;
  view = '';

  constructor(
    public dialogRef: MatDialogRef<UploadPopup>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private service: DrpatilService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.uploadId = this.data.id;
    if (this.data.image) {
      this.profileView = this.data.image;
    }

    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      }
    );
  }

  // ngOnChanges() {
  //   this.triggerObservable;
  // }

  tabChange(val){
    this.showCamera = val?.index;
  }

  // Upload Files Start
  onFileDropped($event) {
    // $event.profileView.preventDefault();
    // $event.profileView.stopPropagation();
    if (!this.imageUrl) {
      this.imageName = $event.dataTransfer.files.item(0).name;
      const reader = new FileReader();
      reader.readAsDataURL($event.dataTransfer.files.item(0));
      reader.onload = () => {
        this.imageChangedEvent = '';
        this.dropBase64 = reader.result;
      };
      $event.push(this.imageChangedEvent);
    }
  }

  fileBrowseHandler($event) {
    // $event.profileView.preventDefault();
    // $event.profileView.stopPropagation();
    this.dropBase64 = '';
    this.imageChangedEvent = $event;
    this.imageName = $event.target.files.item(0).name;

    // this.imageDropEvent = $event;
  }

  // Upload Files End

  // Drag and drop
  onDragover(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragleave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  imagebase64: any;
  async onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    let file = event.dataTransfer.files[0];
    // this.imageChangedEvent = event.dataTransfer;
    this.imageName = event.dataTransfer?.files.item(0).name;
    this.dropBase64 = await this.toBase64(file);
  }
  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  // Drag and drop end

  // Camera Start
  private trigger: Subject<void> = new Subject<void>();

  public showWebcam = true;
  public multipleWebcamsAvailable = false;

  public pictureTaken = new EventEmitter<WebcamImage>();
  public allowCameraSwitch = true;
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();
  public videoOptions: MediaTrackConstraints = {
    width: { ideal: 1000 },
    height: { ideal: 1200 },
  };
  public deviceId: string;
  public errors: WebcamInitError[] = [];

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public webcamImage: WebcamImage = null;

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    const myId = uuid.v4();
    this.pictureTaken.emit(webcamImage);
    this.imageData = webcamImage;
    const base64 = this.imageData._imageAsDataUrl;
    this.dropBase64 = base64;
    this.imageName = myId + '.png';
    this.pictureTaken.emit(webcamImage);
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }
  // Camera End

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  deleteImage() {
    this.imageChangedEvent = '';
    this.dropBase64 = '';
    this.imageName = '';
    this.existingFile = '';
    this.profileView = '';
    this.showCamera = 0;
  }

  imageCropped(event: ImageCroppedEvent) {
    const imageBlob = this.dataURItoBlob(event.base64);
    this.existingFile = new File([imageBlob], this.imageName, {
      type: 'image/png',
    });
  }

  uploadFiles() {
    this.submitting = true;
    var formData: FormData = new FormData();
    formData.append('file', this.existingFile, this.existingFile.name);
    formData.append('userType', 'patient');
    formData.append('fileType', 'profile');
    this.service.postImage(formData).subscribe(
      (res) => {
        this.getImages(res.data);
        this.profileView = '';
        // this.imageName;
        this.service.openSnackBar('Image Uploaded Successfully');
      },
      (err) => {
        this.submitting = false;
        this.service.openSnackBar('Please Upload Image again');
      }
    );
  }

  getImages(value) {
    this.service.getImage(value).subscribe((res) => {
      const payload = {
        data: res.data,
        id: value,
      };
      this.submitting = false;
      this.dialogRef.close({ event: 'uploaded', data: payload });
    });
  }

  closeDialog(value) {
    if (value == true) {
      this.dialogRef.close({ event: 'uploaded' });
    } else {
      this.dialogRef.close({ event: 'cancel', data: this.profileView });
    }
  }
}
