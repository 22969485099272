<div class="task-card">
    <div class="col-lg-12 formSection">
        <div class="row">
            <div class="col-lg-12">
                <div class="row border-bottom">
                    <div class="padding w-100">
                        <label class="headers mb-0">Previous Visits</label>
                        <div *ngIf="router.url.includes('dashboard') && role==='senior_doctor'" class="float-right view-text" (click)="redirectTo()">View All</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="row">
                    <div class="timeline-view" *ngIf="appointmentData.length > 0">
                        <ul>
                            <li *ngFor="let data of appointmentData"
                                class="section" (click)="sendId(data.appointId)">
                                <h6 class="f14 mb-2"><i class="icofont-ui-calendar pr-2"></i>{{data.date}}</h6>
                                <div style="color:#a09e9e; font-size: 14px;">
                                    <p class="mb-0"><i class="icofont-doctor-alt pr-2"></i>{{data.doctor}}</p>
                                    <p>{{data.reason}}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="timeline-view flex-center col-lg-12" *ngIf="appointmentData.length == 0">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <img src="assets/delete-list.png">
                            </div>
                            <div class="col-lg-12 noData mt-4">
                                No Previous Appointment
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
