import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ElementRef,
  QueryList,
  ViewChildren,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-drpatilsclinic-mat-dialog',
  templateUrl: './drpatilsclinic-mat-dialog.component.html',
  styleUrls: ['./drpatilsclinic-mat-dialog.component.scss'],
})
export class DrpatilsclinicMatDialogComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() formGName = '';
  @Input() idModal = '';
  @Input() type = '';
  @Input() idd = '';
  @Input() textidd = '';
  @Input() textControlName = '';
  @Input() name = '';
  @Input() btnId = '';
  @Input() showTextBox = true;
  @Input() IfformGName: boolean = true;
  @Input() ifReset: boolean = false;
  @Input() modalName = '';
  @Input() checkboxData;
  @Output() public getUserData = new EventEmitter<string>();
  @Output() public resetfunction = new EventEmitter<boolean>();
  @Input() selectedData: any = [];
  checkedData: any = [];
  modalSelectText = 'Choose';
  showData: Boolean;

  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;

  ifSelectedData;
  @Input()
  get res(): string {
    return this.ifSelectedData;
  }
  set res(res) {
    this.ifSelectedData = res;
    if (this.ifSelectedData) {
      this.getfunction();
    }
  }

  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnChanges(): void {
    if (this.ifReset) {
      this.resetCheck();
    }
  }

  ngOnInit(): void {
  }

  resetCheck() {
    this.selectedData = []
    if (this.selectedData.length == 0) {
      this.checkboxes.forEach((element: any) => {
        if (element['_checked'] == true) {
          element['_checked'] = false
        }
      });
    }
    this.resetfunction.emit(false);
  }

  getfunction() {
    if (this.checkboxData.length > 0) {
      this.checkboxData.forEach((element) => {
        if (element.selected == true) {
          const payload = {
            checked: true,
          };
          this.typesData(payload, element.name);
        }
      });
    }
  }

  openDialog() {
    this.checkboxData.forEach((element) => {
      let dataExist = this.selectedData.find((o) => o === element.name);
      if (dataExist) {
        element.selected = true;
        this.cdRef.detectChanges();
      }
    });
    document.getElementById(this.idModal).click();
  }

  typesData(e, value) {
    if (e.checked == true) {
      this.selectedData.push(value);
    } else {
      const data = this.selectedData.indexOf(value);
      this.selectedData.splice(data, 1);
    }
  }

  onClickLeft() {
    this.getUserData.emit(this.selectedData);
    document.getElementById(this.idModal).click();
  }
}
