<div class="col-lg-12">
    <div class="row">
        <div class="col-lg-8 p-0 h-100 bg">
            <div class="pl-5 pr-5 pt-5">
                <div><span style="font-size: 40px;">Welcome to </span><span style="font-size: 55px;"><b>Dr.Patil's
                            Clinic</b></span></div>
                <div class="text-center">
                    <img width="85%" src="../../../assets/mainLogo.png">
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="col-lg-12 p-5">
                <div class="col-lg-5 m-auto mt-5 p-4">
                    <form [formGroup]="loginForm" (keydown)="keyDownFunction($event)">
                        <div class="col-lg-12 form-bg">
                            <div class="col-lg-12 pb-4 pt-4 text-center justify-content-center mx-auto">
                                <div class="logo-box mx-auto"><img alt="logo" id="logo" src="../../assets/logo.png"
                                        width="56%"></div>
                            </div>
                            <div class="col-lg-12">
                                <div class="col-lg-12">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Username</mat-label>
                                        <input matInput formControlName="username" id="username" name="username"
                                            value="">
                                        <mat-error
                                            *ngIf="loginForm.get('username').touched && loginForm.get('username').errors"
                                            class="invalid-feedback">
                                            <div *ngIf="loginForm.get('username').errors.required">Username is
                                                required</div>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-12 check">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Password</mat-label>
                                        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password"
                                            id="password" name="password">
                                        <button mat-icon-button matSuffix (click)="hide = !hide" type="button"
                                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </button>
                                        <mat-error
                                            *ngIf="loginForm.get('password').touched && loginForm.get('password').errors"
                                            class="invalid-feedback">
                                            <div *ngIf="loginForm.get('password').errors.required">Password is
                                                required</div>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-12 px-3 text-center pt-2 pb-4">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-4">
                                            <button class="btn-default" (click)="loginSubmit()" [disabled]="submitting">
                                                <div *ngIf="!submitting">Submit</div>
                                                <i *ngIf="submitting" class="fa fa-circle-o-notch fa-spin"
                                                    style="font-size: 18px; color: white;"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>