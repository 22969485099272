import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class DrpatilService {
  drpatilservice = environment.drPatilAPI;
  setPatientDetail$: Observable<any>;
  private setPatientDetailSubject = new Subject<any>();
  registerId: any;
  patientId: any;
  patientDetails: any;
  role = [
    {
      user: 'senior_doctor',
      route: 'doctor'
    },
    {
      user: 'lab_technician',
      route: 'labTechnician'
    }
  ]

  constructor(
    private http: HttpClient,
    public snackBar: MatSnackBar,
    public ngxService: NgxUiLoaderService,
    public activatedRoute: ActivatedRoute,
    public router: Router
  ) {
    this.setPatientDetail$ = this.setPatientDetailSubject.asObservable();
  }

  setPatientDetail(data): any {
    this.patientDetails = data;
    this.setPatientDetailSubject.next(data);
  }

  routerBack() {
    const role = JSON.parse(localStorage.getItem('role'));
    const parentRoute = this.role.filter(res => res.user === role[0]);
    this.router.navigate([
      `${parentRoute[0].route}/userDetails/${this.patientDetails.registerId}/${this.patientDetails.id}/dashboard/${this.patientDetails.appointmentId}`,
    ]);
  }

  calculatePaginationVal(): any {
    const height = (window.innerHeight - 290);
    if (window.innerHeight <= 500) {
      return 4;
    }
    if (window.innerHeight <= 600) {
      return 6;
    }
    if (window.innerHeight <= 657) {
      return 7;
    }
    /* 80% */
    if (window.innerHeight <= 730) {
      return 8;
    }
    /* 80% */
    if (window.innerHeight <= 821) {
      return 11;
    }
    /* 75% */
    if (window.innerHeight <= 876) {
      return 12;
    }
    /* 67% */
    if (window.innerHeight <= 986) {
      return 15;
    }
    /* 50% */
    if (window.innerHeight <= 1314) {
      return 19;
    }
    /* 33.3% */
    if (window.innerHeight <= 1971) {
      return 25;
    }
    /* 25% */
    if (window.innerHeight <= 2628) {
      return 30;
    }
  }

  openSnackBar(message) {
    this.snackBar.open(message, 'close', {
      duration: 3000,
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
      panelClass: ['snackbarStyle'],
    });
  }

  login(data): Observable<any> {
    return this.http.post(this.drpatilservice + '/users/login', data);
  }

  // Role registration API
  getUsersList(roles, num, limit, offset): Observable<any> {
    return this.http.get(
      this.drpatilservice + `/users?roles=${roles}&filterByRoles=${num}&limit=${limit}&offset=${offset}`
    );
  }

  createUser(data): Observable<any> {
    return this.http.post(this.drpatilservice + '/users', data);
  }

  getUserById(id): Observable<any> {
    return this.http.get(this.drpatilservice + `/users/${id}`);
  }

  updataUser(data, id): Observable<any> {
    return this.http.put(this.drpatilservice + `/users/${id}`, data);
  }

  deleteUsers(userId): Observable<any> {
    return this.http.delete(
      this.drpatilservice + `/users/${userId}`
    );
  }

  // Service API
  postService(data): Observable<any> {
    return this.http.post(this.drpatilservice + '/appointments/services', data);
  }
  postSecureCodeUpdate(data): Observable<any> {
    return this.http.post(this.drpatilservice + '/users/admin/code', data);
  }
  getData(): Observable<any> {
    return this.http.get(this.drpatilservice + '/users/admin/code');
  }

  getServicesList(): Observable<any> {
    return this.http.get(this.drpatilservice + '/appointments/services');
  }

  getServiceById(id): Observable<any> {
    return this.http.get(this.drpatilservice + `/appointments/services/${id}`);
  }

  updataService(data, id): Observable<any> {
    return this.http.put(this.drpatilservice + `/appointments/services/${id}`, data);
  }

  deleteServices(serviceId): Observable<any> {
    return this.http.delete(
      this.drpatilservice + `/appointments/services/${serviceId}`
    );
  }

  // Patient registration API
  getPatientList(limit, offset, text): Observable<any> {
    return this.http.get(this.drpatilservice + `/group?limit=${limit}&offset=${offset}&search=${text}`);
  }

  createPatient(data): Observable<any> {
    return this.http.post(this.drpatilservice + '/group', data);
  }

  getPatientById(registerId): Observable<any> {
    return this.http.get(this.drpatilservice + `/group/${registerId}`);
  }

  updatePatient(patientId, data): Observable<any> {
    return this.http.patch(
      this.drpatilservice + `/patients/${patientId}`,
      data
    );
  }

  // Appointments API
  getAppointmentList(num, docid, startDate, endDate, limit, offset, text, regNum, regid): Observable<any> {
    return this.http.get(
      this.drpatilservice +
      `/appointments/detail?filterByServicesFor=0&filterByDoctor=&filterByCreatedBy=0&servicesFor=&doctorId=&createdBy=&filterByStatus=0&status=&startDate_fromdate=${startDate}&startDate_todate=${endDate}&limit=${limit}&offset=${offset}&search=${text}&filterByRegistration=${regNum}&registrationId=${regid}`
    );
  }

  createAppointment(data): Observable<any> {
    return this.http.post(this.drpatilservice + '/appointments', data);
  }

  getAppointmentById(appointmentId): Observable<any> {
    return this.http.get(
      this.drpatilservice + `/appointments/${appointmentId}`
    );
  }

  deleteAppointment(appointmentId): Observable<any> {
    return this.http.delete(
      this.drpatilservice + `/appointments/${appointmentId}`
    );
  }

  updateAppointment(appointmentId, data): Observable<any> {
    return this.http.put(
      this.drpatilservice + `/appointments/${appointmentId}`,
      data
    );
  }
  updateAppointmentWithSecureCode(data): Observable<any> {
    return this.http.put(
      this.drpatilservice + `/appointments/status/complete`,
      data
    );
  }


  getPatientAppointments(patientId): Observable<any> {
    return this.http.get(
      this.drpatilservice + `/appointments/detail?filterByPatient=1&patientId=${patientId}&filterByStatus=1&status=completed`
    );
  }

  // History API
  getHistory(patientId): Observable<any> {
    return this.http.get(this.drpatilservice + `/history/${patientId}`);
  }

  updateHistory(patientId, data): Observable<any> {
    return this.http.patch(this.drpatilservice + `/history/${patientId}`, data);
  }

  // Examination API
  getexaminations(patientId, id): Observable<any> {
    return this.http.get(this.drpatilservice + `/examinations/?filterByPatient=1&patientId=${patientId}&filterByAppointment=1&appointmentId=${id}`);
  }

  updateexaminations(id, data): Observable<any> {
    return this.http.patch(this.drpatilservice + `/examinations/${id}`, data);
  }

  // Advice API
  getadvice(patientId, id): Observable<any> {
    return this.http.get(this.drpatilservice + `/advices/?filterByPatient=1&patientId=${patientId}&filterByAppointment=1&appointmentId=${id}`);
  }

  updateadvice(id, data): Observable<any> {
    return this.http.patch(this.drpatilservice + `/advices/${id}`, data);
  }

  // Investigation API
  getinvestigation(patientId, id): Observable<any> {
    return this.http.get(this.drpatilservice + `/investigations/?filterByPatient=1&patientId=${patientId}&filterByAppointment=1&appointmentId=${id}`);
  }

  updateinvestigation(id, data): Observable<any> {
    return this.http.patch(this.drpatilservice + `/investigations/${id}`, data);
  }

  // Diagnosis API
  postDiagnosis(data): Observable<any> {
    return this.http.post(this.drpatilservice + '/diagnosis', data);
  }

  getDiagnosis(patientId, id): Observable<any> {
    return this.http.get(this.drpatilservice + `/diagnosis/?filterByPatient=1&patientId=${patientId}&filterByAppointment=1&appointmentId=${id}`);
  }

  updateDiagnosis(id, data): Observable<any> {
    return this.http.patch(this.drpatilservice + `/diagnosis/${id}`, data);
  }

  // Treatment API
  getTreatment(registerId, id): Observable<any> {
    return this.http.get(this.drpatilservice + `/treatment?registrationId=${registerId}&filterByAppointment=1&appointmentId=${id}`);
  }

  updateTreatment(id, data): Observable<any> {
    return this.http.patch(this.drpatilservice + `/treatment/${id}`, data);
  }

  //Image Upload API
  postImage(data): Observable<any> {
    return this.http.post(this.drpatilservice + '/files', data);
  }

  getImage(data): Observable<any> {
    return this.http.get(this.drpatilservice + `/files/${data}`);
  }

  //Report Section API
  getReports(cycleId): Observable<any> {
    return this.http.get(this.drpatilservice + `/report?cycle=${cycleId}`);
  }


  //LAB TECHNICIAN
  getAppForLab(limit, offset): Observable<any> {
    return this.http.get(this.drpatilservice + `/appointments/detail?limit=${limit}&offset=${offset}`);
  }

  //AUDIT LOGS
  getAuditLogs(id, limit, offset): Observable<any> {
    return this.http.get(this.drpatilservice + `/audit/logs?appointment=${id}&type=&limit=${limit}&offset=${offset}`);
  }
}
