<form [formGroup]="form" *ngIf="IfformGName">
  <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
    <ng-container formGroupName="{{formGName}}">
      <label class="btn CheckboxRadiobtns" [ngClass]="{'checked': (data.selected == true) }"
        *ngFor="let data of radioButtonData">
        <input type="radio" (click)="onClick(data.name)" formControlName="{{control}}"
          id="{{idd}}{{control}}{{ data.name }}" name="{{name}}" value="{{data.name}}"> {{ data.name }}
      </label>
    </ng-container>
  </div>
</form>
<form [formGroup]="form" *ngIf="!IfformGName">
  <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
    <label class="btn CheckboxRadiobtns" [ngClass]="{'checked': (data.selected == true) }"
      *ngFor="let data of radioButtonData">
      <input type="radio" (click)="onClick(data.name)" formControlName="{{control}}"
        id="{{idd}}{{control}}{{ data.name }}" name="{{name}}" value="{{data.name}}"> {{ data.name }}
    </label>
  </div>
</form>