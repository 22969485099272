// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
var env = require('../../node-env');

export const environment = {
  production: false,
  drPatilAPI: env.DR_PATIL_API,
};

/*

https://drpatilapi.herokuapp.com
http://13.59.83.89/api
https://drpatil.comorin.co/api/backend
http://13.235.132.201/api/backend
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
