  <div class="box">
    <i class="fa fa-sign-out" aria-hidden="true"></i>
    <span class="text-info ml-1">Logout</span>
  </div><hr>

  <div class="col-lg-12 mb-4">
      <h2 class="h5 mb-0 text-center" mat-dialog-title [mat-dialog-close]="false">Are you sure want to Logout?</h2>
    </div>
  <div class="row justify-content-center">
      <div class="col-md-4">
          <button type="button" class="btn-default " [mat-dialog-close]="false">Cancel</button>
      </div>
      <div class="col-md-4">
          <button type="button" class="btn-default-lg btn-danger" [mat-dialog-close]="true" (click)="logout()">Confirm</button>
      </div>
  </div>





