import { style } from '@angular/animations';
import { Directive, HostListener, Output, EventEmitter, HostBinding } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileHandle } from './shared/components/drpatilsclinic-pat-image-upload/file-handle.module';

@Directive({
  selector: '[appDrag]'
})
export class DragDirective {
  // sanitizer: any;

  @Output() files : EventEmitter<FileHandle> = new EventEmitter();


  constructor(private sanitizer : DomSanitizer) { }

  @HostListener('dragover', ['$event'])
  public onDragOver(evt : DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt : DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  public onDrop(evt : DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    let fileHandle : FileHandle = null;
    const file = evt.dataTransfer.files[0];
    const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));

    fileHandle = {file, url}
    this.files.emit(fileHandle);
  }

}
