<a class="float-right mb-3" href="#collapseExample" role="button" id="{{modalBtnId}}" data-toggle="modal"
  attr.data-target="#modal{{modalBtnId}}" data-backdrop="false" data-keyboard="false">
  {{modalName}}
</a>
<div *ngIf="!ifRow">
  <div class="modal fade" attr.id="modal{{modalBtnId}}" tabindex="-1" role="dialog" aria-spanledby="exampleModalspan"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="col-lg-12">
          <div class="modal-header">
            <label class="mb-0 modalHeaders">{{modalName}}</label>
          </div>
        </div>
        <div class="modal-body">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-12 mb-3">
                <div class="table-responsive" style="height: 370px; overflow: auto;">
                  <table class="table table-hover">
                    <tr *ngFor="let columnHead of tableConfig?.columns">
                      <th>{{columnHead.title}}:</th>
                      <td *ngFor="let columnData of tableContent">{{columnHead.title == 'Date' ?
                        (columnData[columnHead?.dataProperty] | date: 'dd/MM/yyyy') :
                        columnData[columnHead?.dataProperty]}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="row justify-content-end">
                  <div class="col-lg-2 col-md-3">
                    <button type="button" class="btn-default" id="submit{{modalBtnId}}" data-dismiss="modal"
                      aria-label="Close">Ok</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="ifRow">
  <div class="modal fade" attr.id="modal{{modalBtnId}}" tabindex="-1" role="dialog" aria-spanledby="exampleModalspan"
    aria-hidden="true">
    <div class="modal-dialog  modal-lg {{tableWidth}} {{tableHeadalign}}" role="document">
      <div class="modal-content ">
        <div class="col-lg-12">
          <div class="modal-header">
            <label class="mb-0 modalHeaders">{{modalName}}</label>
          </div>
        </div>
        <div class="modal-body">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-12 mb-3" style="height: 370px; overflow: auto;">
                <div class="table-responsive border">
                  <table class="table table-hover mb-0">
                    <tr>
                      <th *ngFor="let columnHead of tableConfig?.columns" class="alignCrrct">{{columnHead.title}}:</th>
                    </tr>
                    <tr *ngFor="let columnData of tableContent">
                      <td *ngFor="let columnHead of tableConfig?.columns" class="{{tableBodyalign}}">{{columnHead.title
                        == 'Date' ?
                        (columnData[columnHead?.dataProperty] | date: 'dd/MM/yyyy') :
                        columnData[columnHead?.dataProperty]}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="row justify-content-end">
                  <div class="col-lg-2 col-md-3">
                    <button type="button" class="btn-default" id="submit{{modalBtnId}}" data-dismiss="modal"
                      aria-label="Close">Ok</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>